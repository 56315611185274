const About = () => {
    return (
      <div className="about-contents">
        <img src="./images/profile.jpeg" className="profile-image" alt="profile" />
        <p>
        1993年、奈良県生まれ。<br />
        <br />
        2017年4月に積水ハウス株式会社に入社後、社内システムの開発やスマートホームの設計を担当。主に上流工程側で設計やベンダーコントロールを担当していたが、自分自身の開発スキルを伸ばすため2020年5月に退職。<br />
        2020年8月に現在の会社に入社し、社内の基幹システムの開発を担当。業務要件の開発を進める傍で、モダンな開発環境の整備やジョブスケジューラーの導入を経験。<br />
        同時期に副業として、プログラミング教室のメンター業を開始。<br />
        <br />
        現在はマイクロサービス化やCI/CDの整備といったシステムのモダナイゼーションを中心に取り組んでいます。<br />
        <br />
        エンジニアとしての強みはキャッチアップが早く、経験してきた領域が広いことで、機能要件の開発は勿論、インフラやCI/CDパイプラインの整備、非機能要件の設計にも精通しています。
        </p>
      </div>
    );
};
export default About;