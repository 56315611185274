import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";

import About from "./components/About";
import Top from "./components/Top";
import Works from "./components/Works";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="menu">
          <div className="left">
            <Link to="/">Top</Link>
            <Link to="/about">About</Link>
            <Link to="/works">Works</Link>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/about" element={<About />} />
          <Route path="/works/" element={<Works />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;