const Works = () => {
    return (
      <div className="works-contents">
        <dev className="container" style={{"background-image": 'url("./images/works/icon-3.png")'}}>
          <div className="description" >
            <div className="title">Splitch</div>
            <p>
            誰でも簡単に上手なスケッチが書けるようになるiOSアプリです。
            一枚の画像を分割して表示します。一部分ずつ丁寧に書いていくと誰でも簡単に上手なスケッチができます。
            </p>
            <div className="language">開発言語: C#.NET</div>
            <div className="latest-released-at">作成時期: 2020-04-17</div>
          </div>
        </dev>
        <dev className="container" style={{"background-image": 'url("./images/works/icon-1.png")'}}>
          <div className="description" >
            <div className="title">ScShoPlus</div>
            <p>
            WindowsのPrintScreenによる画面キャプチャ機能を強化するツールです。
            撮影したその場でのトリミングやデスクトップへの保存が可能になります。
            VBで初めて開発したツールをC#に置き換えました。
            </p>
            <div className="language">開発言語: C#.NET</div>
            <div className="latest-released-at">作成時期: 2020-04-17</div>
          </div>
        </dev>
        <dev className="container" style={{"background-image": 'url("./images/works/icon-2.png")'}}>
          <div className="description" >
            <div className="title">e-simulator</div>
            <p>
            学生時代の友人の研究を手伝った際のプログラムです。
            MASによる選挙のシミュレーションの可視化をしています。
            Artisocで実行していたシミュレーションをJavaに置き換えたものです。
            </p>
            <div className="language">開発言語: Java</div>
            <div className="latest-released-at">作成時期: 2017-02-14</div>
          </div>
        </dev>
        <dev className="container" style={{"background-image": 'url("./images/works/icon-4.png")'}}>
          <div className="description" >
            <div className="title">Portfolio</div>
            <p>
            リニューアル前のportfolioです。HTML/CSS + jQueryのみで構成していたサイトをRailsに置き換えました。
            環境はAWSのEC2を利用していましたが、コスト面で安価なVPSに移管しました。
            静的なページですが、ツール一覧の追加、編集、削除とコンタクトフォームからの問い合わせ送信をRailsで実装しております。
            </p>
            <div className="language">開発言語: Ruby on Rails</div>
            <div className="latest-released-at">作成時期: 2020-04-25</div>
          </div>
        </dev>
      </div>
    );
};
export default Works;