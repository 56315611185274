const Top = () => {
    return (
      <div className="top-contents">
        <div className="left-side">
          <div className="logo-text">
            <div className="name">
              NAKAO
              <br />
              SORA
            </div>
            <div className="job">
              Engineer & Designer
            </div>
          </div>
          
        </div>
        <div className="right-side">
          <img src="./images/top-illust.png" className="top-illust" alt="logo" />
        </div>
      </div>
    );
};
export default Top;